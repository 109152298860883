import React from "react"

export const ConnectImages = () => {
	return (
		<section className="flex flex-row flex-wrap px-11 items-center justify-between">
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
			<div className="shadow-lg rounded-lg my-2">
				<img
					className="rounded-t-lg w-[20rem]"
					src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
					alt=""
				/>
				<p className="my-2 sm:text-xl/relaxed text-center">
					Life Sciences Mentors
				</p>
			</div>
		</section>
	)
}
