import React from "react"

export const ClinicImages = () => {
	return (
		<section className="flex flex-row flex-wrap px-11 items-center justify-between">
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
			<img
				className="rounded-t-lg w-[20rem] m-3"
				src="https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
				alt=""
			/>
		</section>
	)
}
