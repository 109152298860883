const Footer = () => {
  return (
    <div className="relative mt-16 bg-deep-purple-accent-400 px-10 lg:px-14 xl:px-16">
      {/* <svg
				className='absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400'
				preserveAspectRatio='none'
				viewBox='0 0 1440 54'
			>
				<path
					fill='currentColor'
					d='M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z'
				/>
			</svg> */}
      <div className="pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div className="flex gap-5 md:flex-row flex-col">
          <div className="md:max-w-md lg:col-span-2">
            <a href="/" className="inline-flex items-center">
              <img src="../../../logo.png" className="w-28" alt="" />
            </a>
            {/* <div className='mt-4 lg:max-w-sm'>
							<p className='text-sm text-deep-purple-50'>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde
								reprehenderit vitae dolor veritatis voluptatibus delectus hic
								quidem earum voluptatum optio?
							</p>
							<p className='mt-4 text-sm text-deep-purple-50'>
								Eaque ipsa quae ab illo inventore veritatis et quasi architecto
								beatae vitae dicta sunt explicabo.
							</p>
						</div> */}
          </div>
          <div className="md:flex md:justify-between w-full grid grid-cols-2 text-center md:text-left space-y-5 md:space-y-0">
            <div className="flex flex-col space-y-3">
              <div className="space-y-2">
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  <a
                    href="/"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400 underline underline-offset-2"
                  >
                    Home
                  </a>
                </p>
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  <a
                    href="/gallery"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400 underline underline-offset-2"
                  >
                    Gallery
                  </a>
                </p>

                {/* <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  <a
                    href="/apply-now"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400 underline underline-offset-2"
                  >
                    Apply Now
                  </a>
                </p> */}
              </div>
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  About
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/about/startups"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Startups
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/teams"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Teams
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/partners"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Partners
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="max-w-48">
              <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                Our Initiatives
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="/initiatives/ipsupport"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                  >
                    IP Support
                  </a>
                </li>
                <li>
                  <a
                    href="/initiatives/preincubationprogram"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                  >
                    Pre Incubation Program
                  </a>
                </li>
                <li>
                  <a
                    href="/initiatives/makerspace"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                  >
                    Makerspace
                  </a>
                </li>
                <li>
                  <a
                    href="/initiatives/incubationprogram"
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                  >
                    Incubation Programs
                  </a>
                </li>
              </ul>
            </div>
            <div className="space-y-3">
              {/* <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  Capital Infusion
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/infusion/investors"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      SAIN Investors
                    </a>
                  </li>
                  <li>
                    <a
                      href="/infusion/startups"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      SAIN Startups
                    </a>
                  </li>
                  <li>
                    <a
                      href="/infusion/services"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Services
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  Benefits
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/benefits/capital"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Capital
                    </a>
                  </li>
                  <li>
                    <a
                      href="/benefits/advisor"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Advisor
                    </a>
                  </li>
                  <li>
                    <a
                      href="/benefits/trade"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Trade
                    </a>
                  </li>
                </ul>
              </div> */}
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  Apply Now
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/apply/student"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      For Incubation
                    </a>
                  </li>
                  <li>
                    <a
                      href="/apply/mentor"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      As Mentor
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="space-y-3">
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  Media
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/media/blog"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="/media/blog"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Annual Report
                    </a>
                  </li>
                  <li>
                    <a
                      href="/media/mediapresence"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Media Presence
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 underline underline-offset-2">
                  Events
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/events/upcomingevents"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Upcoming Events
                    </a>
                  </li>
                  <li>
                    <a
                      href="/events/previousevents"
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Past Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
          <p className="text-sm text-gray-100">
            © Copyright 2024. All rights reserved.
          </p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
