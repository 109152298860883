import React from "react";

export const Services = () => {
  return (
    <div className="w-full bg-[#1D293E] ">
      <h1 className="py-10 lg:py-16 font-bold text-3xl text-center text-white">
        Our Services
      </h1>
      <div className=" grid grid-cols-1  md:grid-cols-2  lg:grid-cols-3 gap-5 pb-16  px-8 lg:px-24">
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Deal Sourcing and Screening
          </h1>
          <p className="text-white">
            SAIN offers comprehensive campaign management services to ensure
            your marketing efforts yield optimal results. From strategic
            planning to execution and ongoing optimization, we handle every
            aspect of your campaign to maximize ROI and achieve your business
            objectives."
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Campaign Management and Optimization:
          </h1>
          <p className="text-white">
            RAIN offers comprehensive campaign management services to ensure
            your marketing efforts yield optimal results. From strategic
            planning to execution and ongoing optimization, we handle every
            aspect of your campaign to maximize ROI and achieve your business
            objectives.
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Market Research and Analysis
          </h1>
          <p className="text-white">
            RAIN conducts thorough market research and analysis to identify
            lucrative investment opportunities. Leveraging industry expertise
            and cutting-edge tools, we provide valuable insights to guide your
            investment decisions and maximize returns.
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Due Diligence and Risk Assessment
          </h1>
          <p className="text-white">
            RAIN conducts rigorous due diligence and risk assessment processes
            to evaluate potential investments. Our comprehensive approach
            ensures that investors have a clear understanding of the risks and
            opportunities associated with each opportunity, empowering them to
            make informed decisions.
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Portfolio Management and Optimization
          </h1>
          <p className="text-white">
            RAIN offers portfolio management services to help investors optimize
            their investment portfolios. From asset allocation to performance
            monitoring, we provide strategic guidance to ensure your portfolio
            aligns with your investment goals and risk tolerance.
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Strategic Partnerships and Networking
          </h1>
          <p className="text-white">
            RAIN facilitates strategic partnerships and networking opportunities
            to connect investors with promising ventures. Leveraging our
            extensive network and industry connections, we create valuable
            synergies that drive mutual success.
          </p>
        </div>
        <div className="border-2 border-white p-10 rounded-sm">
          <h1 className="text-white font-bold mb-6">
            Investment Advisory and Consultation
          </h1>
          <p className="text-white">
            RAIN provides personalized investment advisory and consultation
            services to help investors navigate the complex investment
            landscape. From asset allocation strategies to risk management
            techniques, we offer expert guidance to support your investment
            goals.
          </p>
        </div>
        
      </div>
    </div>
  );
};
