import React from "react"
import { Helmet } from "react-helmet"

import HeroSection from "../../components/common/Hero/HeroSection"
import { ComingSoon } from "../../components/common/extra/ComingSoon"

export const YoungMinds = () => {
	return (
		<>
			<Helmet>
				<title>Young Minds | SIIF</title>
			</Helmet>
			<HeroSection
				image="https://cdn.pixabay.com/photo/2018/02/09/09/49/brain-3141247_960_720.jpg"
				title="Igniting Young Minds"
				// desc="Lorem ipsum dolor sit amet consectetur, adipisicing
				// 		elit. Nesciunt illo tenetur fuga ducimus numquam ea!"
			/>
			<ComingSoon />
			{/* <div className="lg:mx-auto lg:w-full px-8">
        <div className="lg:mx-auto  lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
          <div className="text-base leading-7 text-gray-700 ">
            <p className="pt-3">
              To discover skills in the people responsible for driving any
              organization resulting in attaining the goal.
            </p>
            <h1 className="mt-2 text-lg font-bold tracking-tight text-gray-900 sm:text-2xl">
              Goals :
            </h1>
            <ul className="mt-4 space-y-1 text-gray-600 list-disc pl-4">
              <li>To guide the startups about new technologies</li>
              <li>To assist student/faculty innovators and entrepreneurs</li>
              <li>To integrate the vision of the business models</li>
              <li>To understand customer segment and their needs</li>
              <li>To support the development of the organization and team</li>
              <li>To aid the developing entrepreneur leaders</li>
            </ul>
          </div>
        </div>
      </div> */}
		</>
	)
}
