import React from "react"
import { EventSingleCard } from "../EventSingleCard"
import image1 from "../../../img/events/past_event1.jpg"
import image2 from "../../../img/events/past_event2.jpg"
import image3 from "../../../img/events/past_event3.jpg"
import image4 from "../../../img/events/past_event4.jpg"
import image5 from "../../../img/events/past_event5.jpg"
import image6 from "../../../img/events/past_event6.jpg"
import image7 from "../../../img/events/past_event7.jpg"
import image8 from "../../../img/events/past_event8.jpg"
import image9 from "../../../img/events/past_event9.jpg"
import image10 from "../../../img/events/past_event10.jpg"
import image11 from "../../../img/events/past_event11.jpg"
import image12 from "../../../img/events/past_event12.jpg"
import image13 from "../../../img/events/past_event13.jpg"
import image14 from "../../../img/events/past_event14.jpg"
import { UpcomingEventCard } from "../UpcomingEventCard"

export const PreviousEventCards = () => {
	return (
		<section className="relative items-center justify-center w-full my-3 mx-auto max-w-screen-xl sm:px-6 lg:flex flex-row flex-wrap lg:items-center lg:px-8">
			<UpcomingEventCard
				link={"https://www.instagram.com/p/C0ivaSgPyBa/"}
				image={image3}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/C0OUtoVvWI0/"}
				image={image4}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/Cz75GyyP3oT/"}
				image={image5}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/CyFjyTiPLTd/"}
				image={image6}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/Cx-C9dBPeOU/"}
				image={image7}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/Cw1rRrwPFk_/"}
				image={image8}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/Cw0NZm0pqHo/"}
				image={image9}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/CwVZFHkvAOV/"}
				image={image10}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/CwM-UU4vr1o/"}
				image={image11}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/Cu4BZ9rPYYq/"}
				image={image12}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/CujGIIlvDiP/"}
				image={image13}
			/>
			<UpcomingEventCard
				link={"https://www.instagram.com/p/CvuHWfpPeJn/"}
				image={image14}
			/>
			<UpcomingEventCard
				link="https://www.instagram.com/p/C3h1X3nBO6K/"
				image={image2}
			/>
			<UpcomingEventCard
				link="https://www.instagram.com/p/C3ZzIbsBeef/"
				image={image1}
			/>
			<UpcomingEventCard
				link="https://forms.gle/fawkhYxf5YYJm1R98"
				image={image3}
			/>
		</section>
	)
}
