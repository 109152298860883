import React from "react";
import "./ComingSoon.css";

export const ComingSoon = ({data}) => {
  return (
    <section className="relative min-h-screen h-screen w-full">
      <div class="containerComingSoon">
        <div class="wrapper">
          <div class="content">
            <div class="item">
              <h1>UPDATING SOON</h1>
              <p>{data || "Revamping Soon"}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
