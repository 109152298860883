import React from "react"
import { UpcomingEventCard } from "../UpcomingEventCard"
import image1 from "../../../img/events/upcoming_event4.jpg"
import image2 from "../../../img/events/upcoming_event5.jpg"


export const UpcomingEventCards = () => {
	return (
		<section className="relative items-center justify-center w-full h-screen my-3 mx-auto max-w-screen-xl sm:px-6 lg:flex flex-row flex-wrap lg:items-center lg:px-8">
			{/* no events found */}
			<h1 className="text-4xl font-bold text-center text-gray-800">
				No upcoming events found
			</h1>
			{/* <UpcomingEventCard
				link="https://forms.gle/fawkhYxf5YYJm1R98"
				image={image3}
				// title={"Noteworthy technology acquisitions 2021"}
				// description={
				// 	"Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order"
				// }
			/> */}
		</section>
	)
}
